import { FC, useState } from 'react';
import { TabsContainer } from '../css';

interface TabProps {
	setSelectedTabs: (tab:'CPU'| 'Frame'|'GPU'|'Power'|'Board'|'Fan')=>void;
}
const Tabs:FC<TabProps> = ({setSelectedTabs}) => {
	const [activeTab, setActiveTab] = useState(0);

	const tabs = ['Frame', 'Power', 'Board', 'Fan', 'CPU', 'GPU'];

	return (
		<TabsContainer>
			{tabs.map((tab, index) => (
				<div
					key={tab}
					className={`tab_item ${activeTab === index ? 'active' : index === activeTab - 1 ? 'without_border' : ''}`}
					onClick={() => {
						setActiveTab(index)
						setSelectedTabs(tab as 'CPU'| 'Frame'|'GPU'|'Power'|'Board'|'Fan')
					}}
				>
					{tab}
				</div>
			))}
		</TabsContainer>
	);
};

export default Tabs;
