import numberUtils from 'shared/utils/number';
import { MinerContainer } from './css';
import { Coin, Info, Power, Temp } from './img';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'shared/hooks/redux-helper';
import { GETInfo } from 'api/v1/miner';
import { changeMiner } from 'store/reducers/miner';
import { useDispatch } from 'react-redux';
import InfoModal from './components/info-modal';

const Miner = () => {
	const [caseName, setCaseName] = useState<string>('Frame');
	const balance = useAppSelector(state => state.profile.balance);
	const minerInfo = useAppSelector(state => state.miner);
	const [type, setType] = useState<'Temperature' | 'Profit' | 'Power'>();
	const [buyModalStatus, setBuyModalStatus] = useState<{
		status: boolean;
	}>({
		status: false,
	});
	const dispatch = useDispatch();
	useEffect(() => {
		GETInfo().then(res => {
			dispatch(changeMiner(res));
		});
	}, []);
	const getComponentType = (type: number): string => {
		switch (type) {
			case 0:
				return 'Frame';
			case 1:
				return 'Power';
			case 2:
				return 'Board';
			case 3:
				return 'Fan';
			case 4:
				return 'CPU';
			case 5:
				return 'GPU';
			default:
				return 'unknown';
		}
	};
	useEffect(() => {
		if (minerInfo?.purchedItems) {
			setCaseName(
				[...new Set(minerInfo?.purchedItems
					.slice()
					.sort((a, b) => a.type - b.type)
					.filter(item => item.type !== 3)
					.map(item => getComponentType(item.type)))]
					.join('-'),
			);
		}
	}, [minerInfo?.purchedItems]);

	return (
		<MinerContainer>
			<img className='background' src='/img/miner/background.webp' alt='' />
			<div className='header'>
				<Link to={'/games/miner/shop'}>
					<img src='/img/miner/shop.webp' alt='' width={90} height={90} />
				</Link>
				<div className='coins_container'>
					<div className='coin'>
						<img src='/img/dollar.webp' alt='' />
					</div>
					<p>{numberUtils.formatPriceWithDecimals(balance)}</p>
				</div>
			</div>
			<Link to={'/games/miner/shop'} className='case'  >
				{minerInfo.profit > 8000 ? (
					<img src={`/img/miner/lvl6.webp`} alt='' />
				) : minerInfo.profit > 4000 ? (
					<img src={`/img/miner/lvl5.webp`} alt='' />
				) : minerInfo.profit > 2000 ? (
					<img src={`/img/miner/lvl4.webp`} alt='' />
				) : minerInfo.profit > 1000 ? (
					<img src={`/img/miner/lvl3.webp`} alt='' />
				) : minerInfo.profit > 200 ? (
					<img src={`/img/miner/lvl2.webp`} alt='' />
				) : caseName.length > 1 ? (
					<img src={`/img/miner/${caseName}.gif`} alt='' />
				) : (
					''
				)}
			</Link>
			<img className='table' src='/img/miner/table.webp' alt='' />

			<div className='footer'>
				<div
					className='_container'
					onClick={() => {
						setType('Temperature');
						setBuyModalStatus({ status: true });
					}}
				>
					<div className='tempeture'>
						<Temp className='icon' />
						<p>
							Temp <span>{minerInfo?.temp}°</span>
						</p>
					</div>
					<Info />
				</div>
				<div className='divider' />
				<div
					className='_container'
					onClick={() => {
						setType('Profit');
						setBuyModalStatus({ status: true });
					}}
				>
					<div className='profit'>
						<Coin className='icon' />
						<p>
							Profit <span>{minerInfo?.profit}</span>
						</p>
					</div>
					<Info />
				</div>
				<div className='divider' />
				<div
					className='_container'
					onClick={() => {
						setType('Power');
						setBuyModalStatus({ status: true });
					}}
				>
					<div className='power'>
						<Power className='icon' />
						<p>
							Power <span>{minerInfo?.power}</span>
						</p>
					</div>
					<Info />
				</div>
			</div>
			{buyModalStatus.status && type && (
				<InfoModal type={type} status={buyModalStatus.status} setStatus={status => setBuyModalStatus({ status })} />
			)}
		</MinerContainer>
	);
};

export default Miner;
