import { configureStore } from '@reduxjs/toolkit';
import Profile from './reducers/profile';
import Levels from './reducers/levels';
import Miner from './reducers/miner';

export default configureStore({
	reducer: {
		profile: Profile,
		levels: Levels,
		miner: Miner,
	},
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
});
