import { createSlice } from '@reduxjs/toolkit';
import { IGetInfoResponse } from 'api/v1/miner';

const initialState: IGetInfoResponse = {
	power: 0,
	temp: 0,
	profit: 0,
	purchedItems: [
		{
			model: '',
			modelNo: 0,
			type: 0,
		},
	],
};
export const Miner = createSlice({
	name: 'miner',
	initialState: initialState,
	reducers: {
		changeMiner: (state, action: { payload: IGetInfoResponse }) => {
			state.power = action.payload.power;
			state.temp = action.payload.temp;
			state.profit = action.payload.profit;
			state.purchedItems = action.payload.purchedItems;
		},
	},
});

export const { changeMiner } = Miner.actions;
export default Miner.reducer;
