import { useEffect, useState } from 'react';

export const useCountDown = (targetDate: number = 0) => {
	const [countDown, setCountDown] = useState<number>(targetDate);
	useEffect(() => {
		const myInterval = setInterval(() => {
			setCountDown(s => s - 1000);
		}, 1000);
		if (+days <= 0 && +hours <= 0 && +minutes <= 0 && +seconds <= 0) {
			clearInterval(myInterval);
		}
		return () => {
			clearInterval(myInterval);
		};
	}, []);
	let days: number | string = Math.floor(countDown / (1000 * 60 * 60 * 24));
	let hours: number | string = Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
	let minutes: number | string = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
	let seconds: number | string = Math.floor((countDown % (1000 * 60)) / 1000);

	if (days < 0) {
		days = 0;
	}
	if (hours < 0) {
		hours = 0;
	}
	if (minutes < 0) {
		minutes = 0;
	}
	if (seconds < 0) {
		seconds = 0;
	}

	const setNewCountDown = (state: number) => {
		setCountDown(state);
	};
	if (days <= 9) {
		days = `0${days}`;
	}
	if (hours <= 9) {
		hours = `0${hours}`;
	}
	if (minutes <= 9) {
		minutes = `0${minutes}`;
	}
	if (seconds <= 9) {
		seconds = `0${seconds}`;
	}

	return { days, hours, minutes, seconds, setNewCountDown };
};
