import { FC, useEffect, useState } from 'react';
import Button from 'shared/components/button';
import Modal from 'shared/components/modal';
import useCloseModal from 'shared/hooks/use-close-modal';
import { BuyModalContainer } from '../css';
import { POSTBuy, IGetShopResponse, TShopDto } from 'api/v1/miner';
import { useDispatch } from 'react-redux';
import { changeBalance, changeReplay } from 'store/reducers/profile';
import toast from 'react-hot-toast';
import numberUtils from 'shared/utils/number';

const BuyModal: FC<{
	id: number | null;
	status: boolean;
	data: IGetShopResponse[TShopDto][];
	setStatus: (status: boolean) => void;
	setRefresh: () => void;
}> = ({ status, data, setStatus, id, setRefresh }) => {
	const [_status, onClose] = useCloseModal({ status, setStatus });
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [selectedItemForBuy, setSelectedItemForBuy] = useState<IGetShopResponse[TShopDto][] | null>(null);
	const [miningIssue, setMiiningIssue] = useState<
		| 'InsufficientBalance'
		| 'LowWattage'
		| 'HighTemperature'
		| 'RequirementNotMet'
		| 'IncorrectLevel'
		| 'AlreadyPurchased'
		| 'CooldownActive'
		| null
	>(null);
	const dispatch = useDispatch();
	useEffect(() => {
		const selectedItem = data.find(item => item.id === id) || null;
		setSelectedItemForBuy(selectedItem ? [selectedItem] : null);
	}, [id, data]);

	const onClaimClickHandler = () => {
		if (!isLoading && id && selectedItemForBuy && miningIssue === null) {
			setIsLoading(true);
			POSTBuy(id, selectedItemForBuy[0].type)
				.then(res => {
					window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
					toast.success(' Added successfully');
					dispatch(changeBalance(res));
					dispatch(changeReplay());
					onClose();
					setRefresh();
				})
				.catch(error => {
					setMiiningIssue(error.response.data.data.exceptionType);
					if (
						error.response.data.data.exceptionType !== 'LowWattage' ||
						error.response.data.data.exceptionType !== 'HighTemperature'
					) {
						onClose();
					}
				})
				.finally(() => {
					setIsLoading(false);
				});
		} else {
			onClose();
		}
	};
	const getComponentType = (type: number): string => {
		switch (type) {
			case 0:
				return 'Frame';
			case 1:
				return 'Power';
			case 2:
				return 'Board';
			case 3:
				return 'Fan';
			case 4:
				return 'CPU';
			case 5:
				return 'GPU';
			default:
				return 'unknown';
		}
	};
	return (
		<Modal isBottomSheet status={_status} onClose={onClose} title={''}>
			{selectedItemForBuy && (
				<BuyModalContainer>
					{miningIssue === null && <img src={selectedItemForBuy[0].imageUrl} alt='' className='calender' />}
					{miningIssue !== null && (
						<img
							src={miningIssue === 'LowWattage' ? '/img/shop/low-power.webp' : '/img/shop/high-tem.webp'}
							alt=''
							className='issue_pic'
						/>
					)}
					<div className='content'>
						<h3>
							{miningIssue === null
								? selectedItemForBuy[0].model
								: miningIssue === 'LowWattage'
								? 'Power Low!'
								: 'High Temperature!'}
						</h3>
						{miningIssue !== null && miningIssue === 'LowWattage' ? (
							<p>Upgrade your Power to increase energy and continue mining coins.</p>
						) : (
							miningIssue !== null && <p>Upgrade your Fan to improve cooling and maintain performance.</p>
						)}
						{miningIssue === null && (
							<>
								{selectedItemForBuy[0].type === 0 && <p>Upgrade your Frame</p>}
								{selectedItemForBuy[0].type === 1 && 'wattage' in selectedItemForBuy[0] && (
									<>
										<p className='yellow_text'>Power: {selectedItemForBuy[0].wattage} Watts</p>
										<p>Increase your power</p>
									</>
								)}
								{selectedItemForBuy[0].type === 2 && 'wattage' in selectedItemForBuy[0] && (
									<>
										<p className='yellow_text'>Power: +{selectedItemForBuy[0].wattage} Watts</p>
										<p>Empower your Board</p>
									</>
								)}
								{selectedItemForBuy[0].type === 3 && 'temp' in selectedItemForBuy[0] && (
									<>
										<p className='yellow_text'>
											Power: +{selectedItemForBuy[0].wattage} Watts Temp:{selectedItemForBuy[0].temp}°
										</p>
										<p>Cool your Fan</p>
									</>
								)}
								{(selectedItemForBuy[0].type === 4 || selectedItemForBuy[0].type === 5) &&
									'income' in selectedItemForBuy[0] && (
										<>
											<p className='yellow_text'>
												Power: +{selectedItemForBuy[0].wattage} Watts{' '}
												<span className='blue_text'>Temp:{selectedItemForBuy[0].temp}°</span>
												<span className='green_text'>Profit: {selectedItemForBuy[0].income} C/Hr</span>
											</p>
											<p>Improve your {selectedItemForBuy[0].type === 4 ? 'CPU' : 'GPU'}</p>
										</>
									)}
							</>
						)}
						<Button
							variant='primary'
							onClick={onClaimClickHandler}
							isLoading={isLoading}
							disabled={!selectedItemForBuy[0].canBuy && selectedItemForBuy[0].isBought}
						>
							{miningIssue === null ? (
								<>
									Buy a {getComponentType(selectedItemForBuy[0].type)} (
									<img src='/img/dollar.webp' alt='' />
									{numberUtils.formatPriceWithDecimals(selectedItemForBuy[0].cost)})
								</>
							) : (
								'Continue'
							)}
						</Button>
					</div>
				</BuyModalContainer>
			)}
		</Modal>
	);
};
export default BuyModal;
