import { useEffect, useState } from 'react';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import Icon from 'shared/components/icon';
import numberUtils from 'shared/utils/number';
import { IGetShopResponse, TShopDto } from 'api/v1/miner';
import { useCountDown } from 'shared/hooks/use-count-down';
import { SingleCardContainer } from '../css';
import { useAppSelector } from 'shared/hooks/redux-helper';

const Card = ({
	item,
	select,
	seletedTab,
	buy,
	task,
	canUse,
}: {
	item: IGetShopResponse[TShopDto]&{endTime:number};
	select: () => void;
	seletedTab: string;
	buy: () => void;
	task: () => void;
	canUse: boolean;
}) => {
	const [timer, setTimer] = useState<number>(item.endTime);

	const balance = useAppSelector(state => state.profile.balance);


	useEffect(() => {
		if (item.endTime > Date.now()) {
			const interval = setInterval(() => {
				setTimer(prevTimer => prevTimer - 1000);
			}, 1000);

			return () => clearInterval(interval);
		}
	}, []);
	const getComponentType = (type: number): string => {
		switch (type) {
			case 0:
				return 'Frame';
			case 1:
				return 'Power';
			case 2:
				return 'Board';
			case 3:
				return 'Fan';
			case 4:
				return 'CPU';
			case 5:
				return 'GPU';
			default:
				return 'unknown';
		}
	};
	return (
		<SingleCardContainer
			onClick={() => {
				if (item.isBought || !item.canBuy || item.cost > balance) return;
				if (canUse) {
					buy();
				} else {
					task();
				}
				select();
			}}
		>
			{item.isBought && (
				<>
					<img src='/img/shop/bought.webp' className='tick' />
					<div className='bought' />
				</>
			)}
			{!item.isBought && !item.canBuy && (
				<>
					<div className='lock_caption'>
						<img src='/img/shop/lock.webp' />
						<p className='title'>{item.model}</p>
						{(seletedTab === 'Power' || seletedTab === 'Board') && 'wattage' in item && (
							<p className='power_cap'>{item.wattage} Watts</p>
						)}
						{seletedTab === 'Fan' && 'temp' in item && (
							<p className='fan_cap'>
								Cool Miner <span>{item.temp}°</span>
							</p>
						)}
						{(seletedTab === 'CPU' || seletedTab === 'GPU') && 'income' in item && (
							<p className='cpu_cap'>
								Profit: <span>+{item.income} C/Hr</span>
							</p>
						)}
						<div className='value'>
							<div className='border'>
								Buy {getComponentType(item.type)} {item.requirement}
							</div>
						</div>
					</div>

					<div className='lock' />
				</>
			)}
			{item.cooldownTime && item.cooldownTime > 0 ? (
				<div className='container_timer'>
					<div className='background' />
					<CircularProgressbarWithChildren
						value={(timer / item.endTime) * 100}
						strokeWidth={10}
						styles={buildStyles({
							pathColor: 'rgba(15,233,224,1)',
							trailColor: '#003457',
						})}
					>
						<Icon name='clock' className='clock' />
						<Timer timeLeft={item.cooldownTime} />
					</CircularProgressbarWithChildren>
				</div>
			) : (
				<div className='item_img'>
					<img src={item.imageUrl} alt='' />
				</div>
			)}
			<p className='title'>{item.model}</p>
			{(item.type === 0 || item.type === 2) && <p className='caption'>Purchase to Upgrade</p>}
			{seletedTab === 'Power' && 'wattage' in item && <p className='power_cap'>{item.wattage} Watts</p>}
			{seletedTab === 'Fan' && 'temp' in item && (
				<p className='fan_cap'>
					Cool Miner <span>{item.temp}°</span>
				</p>
			)}
			{(seletedTab === 'CPU' || seletedTab === 'GPU') && 'income' in item && (
				<p className='cpu_cap'>
					Profit: <span>+{item.income} C/Hr</span>
				</p>
			)}
			<div className='value'>
				<div className='border'>
					{(seletedTab === 'CPU' || seletedTab === 'GPU') && 'level' in item && <div className='level'>lvl {item.level}</div>}{' '}
					Cost: {balance >= item.cost ? <img src='/img/dollar.webp' alt='' /> : <img src='/img/silver.webp' alt='' />}
					{numberUtils.formatPriceWithDecimals(item.cost)}
				</div>
			</div>
		</SingleCardContainer>
	);
};
export const Timer = ({ timeLeft }: { timeLeft: number }) => {
	const { hours, minutes, seconds } = useCountDown(timeLeft * 1000);

	const formatTime = () => {
		return +hours > 0
			? `${hours.toString().padStart(2, '0')} : ${minutes.toString().padStart(2, '0')} : ${seconds.toString().padStart(2, '0')}`
			: `${minutes.toString().padStart(2, '0')} : ${seconds.toString().padStart(2, '0')}`;
	};
	return <span>{formatTime()}</span>;
};

export default Card;
