import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
 import { Header, SecretCode } from './components';
import { GamesContainer } from './css';
import { canClaimReward } from 'api/v1/secret-code';
import toast from 'react-hot-toast';
import { Arrow } from './img';

const Games = () => {
	const [statusModal, setStatusModal] = useState(false);

	return (
		<>
			<GamesContainer>
				<Helmet>
					<title>PinEye | games</title>
				</Helmet>
				<div className='top_light'></div>
				<Header />

				<div className='game_list'>
					<Link to={'/games/miner'}>
						<div className='caption'>
							<div className='title'>Build Miner </div>
							<div className='description'>Passive Earning Coins</div>
							<img src='/img/game/miner.webp' className='miner_pic' />
							<div className='btn_miner'>
								<p>Start Mining</p>
								<Arrow />
							</div>
						</div>
					</Link>

					<div
						onClick={() => {
							canClaimReward().then(res => {
								if (!res.data.canClaim) {
									toast.error(res.data.cause);
									setStatusModal(false);
								} else {
									setStatusModal(true);
								}
							});
						}}
					>
						<img src='/img/game/secret_code_popup.webp' alt='' />
						<div className='title'>Secret Code</div>
						<div className='description'>in to Telegram and X</div>
						<div className='btn_code'>
							<p>Enter Code</p>
							<Arrow />
						</div>
					</div>

					<Link to={'/games/lottery'}>
						<img src='/img/game/lottery.webp' alt='' />
						<div className='title'>Big Win Lottery</div>
						<div className='description'>Take Your Chance </div>
						<div className='btn_code'>
							<p>Buy Tickets</p>
							<Arrow />
						</div>
					</Link>
				</div>
			</GamesContainer>
			{statusModal && <SecretCode status={statusModal} setStatus={setStatusModal} />}
		</>
	);
};

export default Games;
