import requestHandler from 'shared/utils/request-handler';
import { IGetShopResponse, IGetInfoResponse, IPOSTBuyResponse ,IGetCanUseMineResponse} from './miner.dto';

const BASE_URL = '/api/v1/Miner';

export const GETShop = async () => {
	return requestHandler.call<IGetShopResponse>({ url: `${BASE_URL}/Shop`, method: 'get' }).then(res => res.data);
};

export const GETInfo = async () => {
	return requestHandler.call<IGetInfoResponse>({ url: `${BASE_URL}/Info`, method: 'get' }).then(res => res.data);
};

export const POSTBuy = async (id: number, type: number) => {
	return requestHandler.call<IPOSTBuyResponse>({ url: `${BASE_URL}/Buy?id=${id}&type=${type}`, method: 'post' }).then(res => res.data);
};

export const GetCanUseMine = async () => {
	return requestHandler.call<IGetCanUseMineResponse>({ url: `${BASE_URL}/CanUserMine`, method: 'get' }).then(res => res.data);
};
