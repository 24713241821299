import { useAppSelector } from 'shared/hooks/redux-helper';
import { HeaderContainer } from '../css';
import numberUtils from 'shared/utils/number';

const Header = () => {
	const balance = useAppSelector(state => state.profile.balance);
	return (
		<HeaderContainer>
			<h3>Your Balance</h3>
			<div className='balance_container'>
				<img src='/img/dollar.webp' alt='' draggable={false} />
				{numberUtils.formatPriceWithDecimals(balance)}
			</div>
			<div className='how'>
				<a href='https://docs.pineye.io/user-guide/' target='_blank' rel='noreferrer'>
 					How it works?
				</a>
			</div>
		</HeaderContainer>
	);
};
export default Header;
