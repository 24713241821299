import { FC } from 'react';
import Button from 'shared/components/button';
import Modal from 'shared/components/modal';
import useCloseModal from 'shared/hooks/use-close-modal';
import { InfoModalContainer } from '../css';
import { Coin, Power, Temp } from '../img';

const InfoModal: FC<{
 	status: boolean;
    type :'Temperature' | 'Profit' | 'Power';
 	setStatus: (status: boolean) => void;
}> = ({
	status,
	type,
	setStatus,
}) => {
	const [_status, onClose] = useCloseModal({ status, setStatus });

	return (
		<Modal isBottomSheet status={_status} onClose={onClose} title={''}>
			<InfoModalContainer>
				{type === 'Power' ? (
					<Power className='calender' />
				) : type === 'Profit' ? (
					<Coin className='calender' />
				) : (
					<Temp className='calender' />
				)}
				<div className='content'>
					<h3>{type}</h3>
					<p>
						{type === 'Temperature'
							? 'CPUs and GPUs generate heat in mining, while Fan reduces it. Keeping temperatures below 50 °C prevents overheating and ensures stable performance.'
							: type === 'Profit'
							? 'The value of your profit determines how many coins you gain per hour (C/Hr) based on your CPUs and GPUs. Boost your profit to earn coins faster.'
							: 'The board, fan, CPUs, and GPUs all require energy to mine coins, so power is needed to generate this energy. Upgrade your power to mine faster.'}
					</p>

					<Button variant='primary' onClick={() => onClose()}>
						Continue
					</Button>
				</div>
			</InfoModalContainer>
		</Modal>
	);
};
export default InfoModal;
