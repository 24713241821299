import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const SplashContainer = styled.div(
	() => css`
		width: 100%;
		height: 100vh;
		background-image: url(/img/loading_Splash.webp);
		background-size: cover;
		background-position: bottom;

		> div {
			padding-top: 270px;
		}

		@media (max-height: 690px) {
			> div {
				padding-top: 130px;
			}
		}
	`,
);
