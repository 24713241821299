import { FC, useEffect, useState } from 'react';
import Button from 'shared/components/button';
import Modal from 'shared/components/modal';
import useCloseModal from 'shared/hooks/use-close-modal';
import { TaskModalContainer } from '../css';
import { GetCanUseMine, IGetCanUseMineResponse } from 'api/v1/miner';
import { useNavigate } from 'react-router-dom';
import Loader from 'shared/components/loader';

const TaskModal: FC<{
	status: boolean;
	setStatus: (status: boolean) => void;
}> = ({ status, setStatus }) => {
	const [_status, onClose] = useCloseModal({ status, setStatus });
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [data, setData] = useState<IGetCanUseMineResponse | null>(null);
	const navigate = useNavigate();
	useEffect(() => {
		GetCanUseMine().then(res => {
			setData(res);
		});
	}, []);

	const onClaimClickHandler = () => {
		setIsLoading(true);
		onClose();
		navigate('/earn');
		setIsLoading(false);
	};

	return (
		<Modal isBottomSheet status={_status} onClose={onClose} title={''}>
			<TaskModalContainer>
				{!data && <Loader />}
				{data && (
					<div className='content'>
						<img src='/img/shop/earn.webp' alt='' className='calender' />
						<h3>Open Air</h3>
						<p>Complete the tasks on the Earn Page</p>
						<ul>
							{data.tasks.map(item => (
								<li key={item.socialTitle}>
									<img
										className='icon'
										src={item.isFollowed ? '/img/shop/circle-check.webp' : '/img/shop/circle-cross.webp'}
										alt=''
									/>{' '}
									{item.socialTitle}
								</li>
							))}
						</ul>
					</div>
				)}
				<Button variant='primary' onClick={onClaimClickHandler} isLoading={isLoading}>
					Go to Earn Page
				</Button>
			</TaskModalContainer>
		</Modal>
	);
};
export default TaskModal;
