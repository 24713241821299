import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const CardsContainer = styled.div(
	() => css`
		position: relative;
		top: 153px;
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 20px;
		padding-bottom: 100px;
		.card {
		
		}
		@media (max-width: 344px) {
			top: 120px;
		}
	`,
);
