import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector } from 'shared/hooks/redux-helper';
import { OverviewContainer } from '../css';
import { GetExchange } from 'api/v1/exchange';
import DotLoader from 'shared/components/dot-loader';
import MinerModal from './miner-modal';
import { useDispatch } from 'react-redux';
import { GETInfo } from 'api/v1/miner';
import { changeMiner } from 'store/reducers/miner';
 const Overview = () => {
	const profile = useAppSelector(state => state.profile);
	const profit = useAppSelector(state => state.miner.profit);
	const [exchangeName, setExchangeName] = useState<string | null>(null);
	const [minerModalStatus, setMinerModalStatus] = useState(false);
	const dispatch = useDispatch();

	useEffect(() => {
		GetExchange().then(res => {
			setExchangeName(res);
		});
		GETInfo().then(res => {
			dispatch(changeMiner(res));
		});
	}, []);

	const getExchangeImage = (exchangeName: string) => {
		switch (exchangeName.toLowerCase()) {
			case 'bybit':
				return <img src='/img/pineye/bybit.webp' className='pic ' alt=' ' />;
			case 'binance':
				return <img src='/img/pineye/binance.webp' className='pic ' alt=' ' />;
			case 'kucoin':
				return <img src='/img/pineye/kucoin.webp' className='pic ' alt=' ' />;
			case 'okx':
				return <img src='/img/pineye/okx.webp' className='pic ' alt=' ' />;
			default:
				return null;
		}
	};

	return (
		<OverviewContainer>
			<div className='card_item_container down '>
				<div className='card_item'>
					<div className='pic right'>
						<img src='/img/pineye/new_profile.webp' alt='pin' width={43} height={43} />
					</div>
					<div className='text_container'>
						<div className='title'>PinEye</div>
						<div className='value'>Level {profile.level}</div>
					</div>
				</div>
			</div>

			<Link to='/exchange' className='card_item_container   '>
				<div className='card_item'>
					<div className='pic'>
						{exchangeName === null || exchangeName === '' ? (
							<img src='/img/icon/exchange.webp' alt='pin' width={38} height={38} />
						) : (
							getExchangeImage(exchangeName)
						)}
					</div>
					<div className='text_container'>
						<div className='title'>Exchange</div>
						<div className='value'>{exchangeName === null ? <DotLoader /> : exchangeName === '' ? 'Choose' : exchangeName}</div>
					</div>
				</div>
			</Link>

			<div className='card_item_container  up' onClick={() => setMinerModalStatus(true)}>
				<div className='card_item'>
					<div className='pic right'>
						<img src='/img/pineye/pineye-coin.webp' alt='pin' width={43} height={43} />
					</div>
					<div className='text_container'>
						<div className='title'>Profit</div>
						<div className='value'> {profit}</div>
					</div>
				</div>
			</div>
			{minerModalStatus && <MinerModal setStatus={() => setMinerModalStatus(false)} status={true} />}
		</OverviewContainer>
	);
};
export default Overview;
