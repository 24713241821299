import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const HeaderContainer = styled.header(
	({ theme }) => css`
		position: relative;
		z-index: 2;
		color: ${theme.colors.text.primary};
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 16px;
		margin-bottom: 11px;

		h1 {
			font-size: 24px;
			font-weight: 600;
			line-height: 30px;
			margin: 0;
		}

		p {
			color: #fff;
			font-size: 16px;
			font-weight: 400;
			max-width: 260px;
			text-align: center;
		}
	`,
);
