    import { css } from '@emotion/react';
    import styled from '@emotion/styled';

    export const TaskModalContainer = styled.section(
        ({ theme }) => css`
        padding: 0 24px 32px;
        position: relative;
        text-align: center;
        color: ${theme.colors.text.primary};
        display: flex;
        flex-direction: column;

        .calender {
            width: 130px;
            height: 260px;
            position: absolute;
            top: -250px;
            left: 0;
            right: 0;
            margin: auto;
        }
    
        .content{
            text-align: center;
            color: ${theme.colors.text.primary};
            display: flex;
            flex-direction: column;
            gap: 24px;
            
            h3{
                font-size: 24px;
                font-weight: 500;
                line-height: 24px;
                text-align: center;

                }
    
            p{
                font-size: 18px;
                font-weight: 400;
                line-height: 20px;
                text-align: center;
            }
            ul{
                flex-direction: column;
                display: flex;
                align-items: center;
                justify-content: center;
                align-content: center;
                text-align: center;
                align-self: center;
                li{
                    align-self: baseline;
                    list-style-type: none;
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    margin-bottom: 8px;
                    
                    .icon{
                        width: 20px;
                        height: 20px;
                    }
                }
            }
            button {
                width: 100%;
                font-size: 18px;
                font-weight: 500;
                line-height: 22.28px;
                img{
                    width:20px;
                    height:20px;
                    margin-bottom:4px;
                    }
                }
        }
        @media (max-height: 667px) {
            padding: 0 24px 10px;
            gap: 16px;
            .calender {

                top: -230px;
            }
            .content{
                height: 200px;
                overflow: auto;
                display: flex;
                flex-direction: column;
                gap: 16px;
                h3 {
                    padding-top:20px;
                line-height: 20px;
                font-size: 20px;
                }
                p {
                line-height: 15px;
                font-size: 14px:
                }
            
            }
        }
        `,
    );
