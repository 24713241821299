import { FC, useEffect, useState } from 'react';
import { CardsContainer } from '../css';
import { GetCanUseMine, IGetCanUseMineResponse, IGetShopResponse, TShopDto } from 'api/v1/miner';
import BuyModal from './buy-modal';
import Card from './single-card';
import TaskModal from './task-modal';
interface ICardsProps {
	data: (IGetShopResponse[TShopDto]&{endTime:number})[];
	seletedTab: string;
	setRefresh: (refresh: number) => void;
	refresh: number;
}

const Cards: FC<ICardsProps> = ({ data, seletedTab, setRefresh, refresh }) => {
	const [buyModalStatus, setBuyModalStatus] = useState<{
		status: boolean;
	}>({
		status: false,
	});
	const [taskModal, setTaskModal] = useState<{
		status: boolean;
	}>({
		status: false,
	});
	const [selectedId, setSelectedId] = useState<number | null>(null);
	const [canUse, setCanUse] = useState<IGetCanUseMineResponse['canMine']>(false);
	useEffect(() => {
		GetCanUseMine().then(res => {
			setCanUse(res.canMine);
		});
	}, []);
	return (
		<CardsContainer>
			{data.map((item, index) => {
				return (
					<Card
						key={`${item.id}-${index}`}
						item={item}
						select={() => {
							setSelectedId(item.id);
						}}
						seletedTab={seletedTab}
						buy={() => setBuyModalStatus({ status: true })}
						task={() => setTaskModal({ status: true })}
						canUse={canUse}
					/>
				);
			})}
			{buyModalStatus.status && (
				<BuyModal
					setRefresh={() => setRefresh(refresh + 1)}
					status={buyModalStatus.status}
					setStatus={status => setBuyModalStatus({ status })}
					data={data}
					id={selectedId}
				/>
			)}
			{taskModal.status && <TaskModal status={taskModal.status} setStatus={status => setTaskModal({ status })} />}
		</CardsContainer>
	);
};

export default Cards;
