import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const GamesContainer = styled.div(
	() => css`
		padding: 24px 24px 120px;
		background: linear-gradient(180deg, #2775a6 0%, rgba(127, 178, 211, 1) 36%, rgba(34, 113, 161, 1) 100%);

		.top_light {
			width: 187px;
			height: 187px;
			background: rgba(145, 205, 219, 0.6);
			filter: blur(100px);
			position: absolute;
			left: 0;
			right: 0;
			top: -120px;
			margin: auto;
		}

		.game_list {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-template-rows: auto auto;
			grid-row: 1 / span 1;
			grid-column: 1 / span 2;
			gap: 14px;
			& > *:first-child {
				grid-row: 1;
				grid-column: 1 / span 2;
				position: relative;
				width: 100%;
				height: 161px;
				background-image: url('img/game/background-green.webp');
				background-size: cover;
				background-position: center;
				border-radius: 8px;
				box-shadow: 0px 2px 2px 0px #089697a6;

				.caption {
					display: grid;
					grid-template-columns: 1fr 1fr;
					grid-template-rows: 1fr 1fr;
					grid-row: 1 / span 1;
					grid-column: 1 / span 2;
					z-index: 2;
					position: relative;
				}
				.miner_pic {
					grid-row: 1;
					grid-column: 2;
					position: absolute;
					top: 10px;
					right: 0px;
					width: 200px;
					z-index: 1;
				}
				.title {
					position: absolute;
					top: 38px;
					left: 20px;
					grid-row: 1;
					grid-column: 1;
					font-size: 22px;
					font-weight: 500;
					line-height: 27.24px;
					color: #ffffff;
					z-index: 2;
				}
				.description {
					grid-row: 2;
					grid-column: 1;
					position: absolute;
					top: 66px;
					left: 20px;
					color: #ffffff;
					font-size: 13.5px;
					font-weight: 400;
					line-height: 16.38px;
					z-index: 2;
				}
				.btn_miner {
					grid-row: 2;
					grid-column: 1;
					position: absolute;
					top: 100px;
					left: 20px;
					border-radius: 18px;
					background: #04c4ba;
					height: 37px;
					border: 1px solid transparent;
					background-image: linear-gradient(#04c4ba, #04c4ba),
						linear-gradient(225.39deg, rgba(255, 255, 255, 0.6) 35.3%, rgba(35, 135, 197, 0.27) 67.25%);
					background-origin: border-box;
					background-clip: content-box, border-box;
					display: flex;
					align-items: center;
					justify-content: space-between;
					color: #ffffff;
					p {
						font-size: 15px;
						font-weight: 500;
						line-height: 18.57px;
						text-align: center;
						padding: 10px 8px;
					}

					svg {
						margin-right: 4px;
					}
				}
			}

			& > *:nth-child(2),
			& > *:nth-child(3) {
				grid-row: 2;
				position: relative;
				border-radius: 8px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				text-align: center;
				color: #ffffff;
				gap: 8px;
				padding: 8px 4px;
				img {
					height: 99px;
				}
				.title {
					font-size: 18px;
					font-weight: 500;
					line-height: 22.28px;
					text-align: center;
				}
				.description {
					font-size: 13.5px;
					font-weight: 400;
					line-height: 16.38px;
					text-align: center;
				}
				.btn_code {
					border-radius: 18px;
					background: #8d8fe9;
					height: 37px;
					border: 1px solid transparent;
					background-image: linear-gradient(#8d8fe9, #8d8fe9),
						linear-gradient(225.39deg, rgba(255, 255, 255, 0.6) 35.3%, rgba(35, 135, 197, 0.27) 67.25%);
					background-origin: border-box;
					background-clip: content-box, border-box;
					display: flex;
					align-items: center;
					justify-content: center;
					color: #ffffff;
					p {
						font-size: 15px;
						font-weight: 500;
						line-height: 18.57px;
						text-align: center;
						padding: 10px 8px;
					}
					svg {
						margin-right: 4px;
					}
				}
			}

			& > *:nth-child(2) {
				grid-column: 1;
				background-image: url('img/game/background-pink.webp');
				background-size: cover;
				background-position: center;
			}

			& > *:nth-child(3) {
				grid-column: 2;
				background-image: url('img/game/background-blue.webp');
				background-size: cover;
				background-position: center;

				.btn_code {
					background: #51b4ed;
					background-image: linear-gradient(#51b4ed, #51b4ed),
						linear-gradient(225.39deg, rgba(255, 255, 255, 0.6) 35.3%, rgba(35, 135, 200, 0.27) 67.25%);
					border: 1px solid transparent;
					background-origin: border-box;
					background-clip: content-box, border-box;
				}
			}
			gap: 16px;
		}
		@media (max-width: 344px) {
			.game_list {
				& > *:first-child {
					.miner_pic {
						width: 152px;
					}
				}
			}
		}
	`,
);
