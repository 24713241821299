import { FC } from 'react';
import Button from 'shared/components/button';
import Modal from 'shared/components/modal';
import useCloseModal from 'shared/hooks/use-close-modal';
import { MinerModalContainer } from '../css';
import { useNavigate } from 'react-router-dom';

const MinerModal: FC<{ status: boolean; setStatus: (status: boolean) => void }> = ({ status, setStatus }) => {
	const [_status, onClose] = useCloseModal({ status, setStatus });
	const navigate = useNavigate();
	const onCloseClickHandler = () => {
		onClose();
	};
	return (
		<Modal status={_status} onClose={onCloseClickHandler} title='' isBottomSheet>
			<MinerModalContainer>
				<img src='img/pineye/miner.webp' alt='' className='header_img' />
				<p className='title'>Build Your Miner</p>
				<p className='caption'>Build and upgrade your miner to start earning a passive income.</p>

				<Button variant='primary' onClick={() => navigate('/games/miner')}>
					Start Mining
				</Button>
			</MinerModalContainer>
		</Modal>
	);
};

export default MinerModal;
