import { css } from '@emotion/react';
import styled from '@emotion/styled';
export const HomeContainer = styled.div(
	() => css`
		padding: 0 0 40px;
		overflow-x: hidden;
		min-height: 100vh;
		height: 100%;
		background: linear-gradient(180deg, #26709E 10%,#357CA8 100%);
		.top_left_light {
			position: absolute;
			left: -16px;
			top: -43px;
			background: #357ca8;
			filter: blur(100px);
			width: 200px;
			height: 200px;
			pointer-events: none;
			touch-action: none;
		}
	`,
);
