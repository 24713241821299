import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const OverviewContainer = styled.section(
	({ theme }) => css`
		display: flex;
		justify-content: center;
		user-select: none;
		align-items: center;
		padding: 30px;
		/* background: linear-gradient(180deg, rgba(36, 110, 156, 1) 0%, rgba(48, 118, 163, 1) 100%); */
		position: relative;
		gap: 9px;
		.card_item_container {
			background: #2387c5;
			border: 1px solid transparent;
			background-image: linear-gradient(#2387c5, #2387c5),
				linear-gradient(190deg, rgba(255, 255, 255, 0.6) 35.3%, rgba(35, 135, 197, 0.27) 67.25%);
			background-origin: border-box;
			background-clip: content-box, border-box;
			/* width: 33%; */
			text-align: left;
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 3px;
			border-radius: 6px;
			box-shadow: 2px 1px 4.7px 0px #004c9e6b;
			&.up {
				background: #2387c5;
				border: 1px solid transparent;
				background-image: linear-gradient(#2387c5, #2387c5),
					linear-gradient(27.39deg, rgba(255, 255, 255, 0.6) 35.3%, rgba(35, 135, 197, 0.27) 67.25%);
				background-origin: border-box;
				background-clip: content-box, border-box;
			}
			&.down {
				background: #2387c5;
				border: 1px solid transparent;
				background-image: linear-gradient(#2387c5, #2387c5),
					linear-gradient(334deg, rgba(255, 255, 255, 0.6) 35.3%, rgba(35, 135, 197, 0.27) 67.25%);
				background-origin: border-box;
				background-clip: content-box, border-box;
			}
			.card_item {
				padding: 12px 6px;
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 3px;
				color: ${theme.colors.text.primary};
				.pic {
					width: 38px;
					height: 38px;
					&.right {
						width: 42px;
						height: 42px;
					}
				}
				.text_container {
					display: flex;
					flex-direction: column;
					gap: 2px;
					align-items: left;
					justify-content: center;
					.title {
						font-size: 15px;
						font-weight: 400;
						line-height: 18.57px;
					}
					.value {
						font-size: 12px;
						font-weight: 400;
						line-height: 14.86px;
					}
				}
			}
		}
		@media(max-width:344px){
			.card_item_container {
				.card_item {
					padding: 10px 4px;
					 gap:0px 6px;
					.pic {
					width: 34px;
					height: 34px;
					&.right {
						width: 38px;
						height: 38px;
					}
				}
				}
			}
		}
	`,
);
