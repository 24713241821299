import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const MinerContainer = styled.div(
	() => css`
		background: linear-gradient(180deg, #2775a6 0%, rgba(127, 178, 211, 1) 36%, #3188ca 70%);
		color: #fff;
		position: relative;
		height: 100vh;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		.background {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			margin: auto;
			width: 100%;
			height: 100vh;
		}
		.table {
			position: absolute;
			top: 40vh;
			left: 0;
			right: 0;
			margin: auto;
			width: 100%;
		}
		.case {
			position: absolute;
			display: flex;
			justify-content: center;
			align-items: flex-start;
			top: calc(40vh - 100px);
			z-index: 2;
			left: 0;
			right: 0;
			margin: auto;

			img {
				width: 60%;
			}
		}
		.header {
			position: relative;
			padding: 20px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			.coins_container {
				display: flex;
				justify-content: space-between;
				align-items: center;
				border-radius: 18px;
				background: #126090;
				height: 37px;
				border: 1.5px solid transparent;
				background-image: linear-gradient(#126090, #126090),
					linear-gradient(225.39deg, rgba(255, 255, 255, 0.6) 35.3%, rgba(35, 135, 197, 0.27) 67.25%);
				background-origin: border-box;
				background-clip: content-box, border-box;
				p {
					font-size: 15px;
					font-weight: 500;
					line-height: 18.57px;
					text-align: center;
					padding: 10px;
				}
				.coin {
					width: 41px;
					height: 41px;
					display: flex;
					justify-content: center;
					align-items: center;
					background: #0d7dc2;
					border-radius: 100%;
					border: 2px solid transparent;
					background-origin: border-box;
					background-clip: content-box, border-box;
					border: 2px solid transparent;
					background-image: linear-gradient(#0d7dc2, #0d7dc2),
						linear-gradient(225.39deg, rgba(255, 255, 255, 0.6) 35.3%, rgba(35, 135, 197, 0.27) 67.25%);
					background-origin: border-box;
					background-clip: content-box, border-box;
					img {
						width: 24px;
						height: 24px;
					}
				}
			}
		}

		.footer {
			position: relative;
			padding-bottom: 120px;
			display: grid;
			grid-template-columns: 1fr 2px 1fr 2px 1fr;
			grid-template-rows: 1fr;
			grid-column-gap: 0px;
			grid-row-gap: 0px;
			align-items: flex-end;

			._container {
				display: flex;
				justify-content: center;
				.tempeture,
				.profit,
				.power {
					display: flex;
					justify-content: center;
					flex-direction: column;
					align-items: center;
					font-size: 11px;
					font-weight: 400;
					line-height: 13.34px;
					span {
						line-height: 15px;
						font-size: 13px;
					}
					.icon {
						width: 46px;
						height: 46px;
					}
				}
			}

			.divider {
				width: 1.5px;
				height: 39px;
				background-color: #ffffff36;
				margin-top: 20px;
			}
		}
		@media (max-height: 665px) {
			.case {
				top: calc(40vh - 80px);
				img {
				width: 55%;
			}
			}
		}
	`,
);
